<template>
 <div :class="windowClass">
   <v-container>


        <v-row>
            <v-col cols="12">
                  <v-card dense>
        <v-toolbar>
            TICKETS
            <v-spacer/>
               <span style="width: 200px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="From Date"
                                 :value="from_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false" />
                    </v-menu>
               </span>
                <span style="width: 200px; margin-top: 24px;" class="ml-2"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="To Date"
                                 :value="to_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, getItems()" />
                    </v-menu>
               </span>
               <v-icon right color="primary" @click="dlToCsv()">mdi-download</v-icon>
        </v-toolbar>
        <v-card-text>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
                <v-row>
                 <v-col cols="6" class="pa-5 text-left  text-h5">
                   <p>
                      TOTAL UNCLAIM: {{$money(UNCLAIM)}}
                   </p>
                   <p>
                      Zamboanga City Unclaim: {{$money(city_unclaim)}}
                   </p>  
                    <p>
                      Sibugay Unclaim: {{$money(sib_unclaim)}}
                   </p> 
                    <p>
                      JL GROUP Unclaim: {{$money(jl_unclaim)}}
                   </p> 
                    
                </v-col>
              <v-col cols="6" class="pa-5 text-right  text-h4">
                      TOTAL CLAIMED: {{$money(CLAIMED)}}
                </v-col>

                </v-row>
            <table class="va-table def-font" id="win-table">
                <tr>
                    <!-- <th>DRAW ID</th> -->
                    <th>GAME</th>
                    <th>DRAW</th>
                     <th>AGENT</th>
                     <th>TICKET NO.</th>
                      <th class="text-right">TICKET AMOUNT</th>
                    <th class="text-right">WIN</th>
                    <th class="text-right">STATUS</th>
                </tr>
                 <tr v-for="(item,index) in items" :key="index" >
                     <!-- <td class="border_bot">{{item.draw_id}} </td> -->
                    <td class="border_bot">{{item.game}} </td>
                    <td class="border_bot">{{item.draw_date}} </td>
                    <td class="border_bot">{{item.username}} </td>
                    <td class="border_bot">{{item.ticket_id}} </td>
                    <td class="border_bot text-right">{{$money(item.ticket_amount)}} </td>
                    <td class="border_bot text-right">{{$money(item.amount * 500)}}</td>
                   <td class="border_bot text-right"><v-chip small dark :color="$StatusColor(item.status)">{{ item.status}}</v-chip></td>
                </tr>
                <tr>
                    
                     <!-- <th  class="text-right" style="background: #efefef;" colspan="2">TOTAL:</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALGROSS)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALHITS)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALPAYOUT)}}</th> -->
                </tr>
               
            </table>
           
        </v-card-text>     
    </v-card>
            </v-col>
        </v-row>
   </v-container>
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      report_type:'GAME GROSS OVERALL',
      loading: false,
      to_menu: false,
      to_date:'',
        from_menu: false,
      from_date:'',
      items:[],
      city_unclaim: 0,
      sib_unclaim: 0,
      jl_unclaim: 0,
      areas:[]
      
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
       
        } 
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      UNCLAIM(){
          var total = this.items.reduce((res,item)=>{
            if(item.status == "unclaimed") {
              res += item.amount * 500
            }
           return res
          },0)
          return total
      },
        CLAIMED(){
          var total = this.items.reduce((res,item)=>{
            if(item.status == "claimed") {
              res += item.amount * 500
            }
           return res
          },0)
          return total
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
       getItems(){
          this.loading = true
            this.items =[]
            var param = {}
            param.from_date = this.from_date
            param.to_date = this.to_date
            param.Method = "win_tickets"
             this.$http.post("report/transactions",param).then(response => {
                response.data.items !=null?this.items = response.data.items:this.items =[]
                 response.data.sib_unclaim !=null?this.sib_unclaim = response.data.sib_unclaim:this.sib_unclaim =0
                  response.data.city_unclaim !=null?this.city_unclaim = response.data.city_unclaim:this.city_unclaim =0
                 response.data.jl_unclaim !=null?this.jl_unclaim = response.data.jl_unclaim:this.jl_unclaim =0
                this.loading = false
          }).catch(e => {
            console.log(e.data)
          })
      },
       dlToCsv(){
            this.DownloadToCsv("win-table", 'Winnings-' + this.from_date + '-to-' + this.to_date)
        }
    }
  }
</script>